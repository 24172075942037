@import "~bootstrap/scss/bootstrap";

.theme-dark {
  /* redefine theme colors for dark theme */
  $primary: #012345;
  $secondary: #111111;
  $success: #222222;
  $dark: #000;
  
  $theme-colors: (
      "primary": $primary,
      "secondary": $secondary,
      "success": $success,
      "danger": $danger,
      "info": $indigo,
      "dark": $dark,
      "light": $light,
  );

  /* redefine theme color variables */
  @each $color, $value in $theme-colors {
      --#{$variable-prefix}#{$color}: #{$value};
  }

  /* redefine theme color rgb vars (used for bg- colors) */
  $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
  @each $color, $value in $theme-colors-rgb {
      --#{$variable-prefix}#{$color}-rgb: #{$value};
  }

  $body-color: #eeeeee;
  $body-bg: #263C5C;
  
  --#{$variable-prefix}body-color: #{$body-color};
  --#{$variable-prefix}body-bg: #{$body-bg};

  @import "bootstrap";
}