.ThemeSelector {

  margin: 5px;

  label {
    display: flex;
    width: 60px;
    height: 30px;
    justify-content: space-between;
    align-items: center;
    padding: 0 6px;
    background: #77b5fe;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    transition: 0.3s;

    i {
      font-size: 18px;
    }

    .Sun {
      transform: translateX(30px);
      color: gold;
      opacity: 1;
    }

    .Moon {
      color: #fff;
      opacity: 0;
    }

    .Ball {
      position: absolute;
      display: block;
      width: 20px;
      height: 20px;
      top: 5px;
      left: 5px;
      background: #fff;
      border-radius: 50%;
      transition: 0.8s;
    }
  }

  input:checked + label {
    background: #222;

    .Sun {
      transform: translateX(0) translateY(5px);
      opacity: 0;
    }

    .Moon {
      transform: translateX(-30px);
      opacity: 1;
    }

    .Ball {
      transform: translateX(30px);
      background: #c0c0c0;
    }
  }

  input {
    display: none;
  }
}